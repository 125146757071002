<template>
  <div class="section-history">
    <div class="section-history__bg sp-appear">
      <img class="lazyload" :data-src="webp(require(`../../../public/img/detail/${page}/sp/paralax.png`))" :alt="`${page}`">
    </div>
    <div class="container">
      <div class="section-history__wrapper">
        <div class="section-history__inner">
          <div class="section-history__row">
            <div class="section-history__col">
              <div class="section-history__title">
                <img :class="`lazyload ${$i18n.locale}`" :data-src="webp(require(`../../../public/img/detail/karamucho/txt-history-${$i18n.locale}.png`))" :alt="`${page} history`">
              </div>
            </div>
            <div class="section-history__col">
              <div class="section-history__content">
                <div class="section-history__txt" v-html="$t(`${page}.history`)"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'History',
  props: {
    page: String
  },
  data() {
    return { 
      karamucho: 'KARAMUCHO first appeared in the Japan market in September, 1984. At the time, it was the 1st chips in Japan to introduce a spicy flavor with such special taste, that they received a handful of compliments and love from the snack consumers. By then, the chips brand had gained its reputation primarily through word of mouth, and then created the biggest buzz ever in the years later. Additionally, people love all the ads and also the brand character so much. KARAMUCHO has had a history of over 30 years of business in Japan, but the KARAMUCHO fever and its phenomenal hot spicy flavor still remains the most loved and popular spicy potato chips among the consumers',
      koimucho: 'KoiMUCHO is a non-spicy snack brand from Koikeya - one of the leading Japanese snack company which has introduced many popular snack brands with the highest quality. Doraemon is the brand image and embassador of KoiMUCHO.\n' +
              'The first product "KoiMUCHO Corn snack - Corn milk Flavour" was first launched in Vietnam\'s market in November 2018. The product is the perfect combination of the natural scent and the light sweet flavor from the grains of corn which was and still is deeply in the sincere heart of little snack angle fans in Vietnam. In addition to that, early in this May 2019, the company has presented other two products which are "KoiMUCHO Corn Snack - BBQ flavour" and "KoiMUCHO Corn Snack - Garlic Butter Flavour" respectively. These two new products have special and unique taste originated from Japan, and are expected to be the crunchy tasteful hard-to-turn-down snack for the little kids in Vietnam.'
    }
  }
}
</script>

<style lang="scss">
  @import '../../assets/scss/mixin/_mixin';
  .koimucho {
    .section-history {
      &:before {
        @include media(phone) {
          content: "";
          position: absolute;
          left: -2.5rem;
          top: -40%;
          width: 75%;
          padding-top: 170%;
          background: url(../../../public/img/detail/bui.png) no-repeat left center / contain;
          z-index: 0;
        }
      }
    }
  }
  .section-history {
    position: relative;
    margin-bottom: 18rem;
    //transform: translateY(-15rem);
    z-index: 0;

    @include media(set-max-w, 1366) {
      margin-bottom: 13rem;
    }
    @include media(tablet) {
      margin-bottom: 8rem;
      margin-top: 0;
    }
    @include media(phone) {
      //transform: translateY(-18rem);
      padding-top: 10rem;
      /*padding-bottom: 5rem;*/
      //margin-top: 44rem;
      margin-bottom: 0;
    }

    .container {
      position: relative;

      @include media(tablet) {
        width: 600px;
      }
      @include media(phone) {
        padding: 0 2.5rem 0 4rem;
      }
    }            
    
    &__row {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -4rem;
    }
    &__bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 0;
    }
    &__col {
      padding: 0 4rem;
      &:first-child {

        @include media(tablet) {
          width: 100%;
        }
        @include media(phone) {
          margin-bottom: 2.5rem;
          width: 100%;
        }
      }
      &:last-child {
        flex: 1;
        
        @include media(tablet) {
          width: 100%;
        }
        @include media(phone) {
          width: 100%;
        }
      }
    }
    &__txt {
      max-width: 100%;
      font-size: 1.6rem;
      line-height: 3rem;
      color: #ffffff;
    }
  }
  .webp {
    .koimucho {
      .section-history {
        &:before {
          @include media(phone) {
            background: url(../../../public/img/detail/bui.webp) no-repeat left center / contain;
          }
        }
      }
    }
  }
</style>